export const appHead = {"meta":[{"charset":"utf-8"},{"http-equiv":"X-UA-Compatible","content":"IE=edge"},{"name":"viewport","content":"width=device-width,height=device-height,initial-scale=1.0,user-scalable=0,minimum-scale=1.0,maximum-scale=1.0,viewport-fit=cover"},{"name":"format-detection","content":"telephone=no"},{"name":"description","content":"A file manager and fine-tune studio."},{"name":"og:type","property":"og:type","content":"website"},{"name":"og:url","property":"og:url","content":"https://munin.studio"},{"name":"og:site_name","property":"og:site_name","content":"munin.studio"},{"name":"og:title","property":"og:title","content":"Munin Studio - A file manager and fine-tune studio."},{"name":"og:description","property":"og:description","content":"A file manager and fine-tune studio."},{"name":"og:image","property":"og:image","content":"https://munin.studio/og/index.jpg"},{"name":"msapplication-TileColor","content":"#000000"},{"hid":"theme-color","name":"theme-color","content":"#000000"},{"name":"mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-title","content":"munin.studio"}],"link":[{"rel":"canonical","href":"https://munin.studio"},{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[{"async":true,"src":"https://accounts.google.com/gsi/client"},{"async":true,"src":"https://www.googletagmanager.com/gtag/js?id=undefined"},{"children":"\n            window.dataLayer = window.dataLayer || [];\n            function gtag(){dataLayer.push(arguments);}\n            gtag('js', new Date());\n            gtag('config', 'undefined');"}],"noscript":[],"htmlAttrs":{"class":"h-full"},"bodyAttrs":{"class":"antialiased overscroll-x-none"},"title":"Munin Studio - A file manager and fine-tune studio."}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false