import { default as _91model_slug_93F3WYg2ATtWMeta } from "/vercel/path0/pages/@[team_name]/[model_slug].vue?macro=true";
import { default as _91folder_id_93KHuFqxw2F8Meta } from "/vercel/path0/pages/@[team_name]/folder/[folder_id].vue?macro=true";
import { default as _64_91team_name_93UljLzsfGDpMeta } from "/vercel/path0/pages/@[team_name].vue?macro=true";
import { default as authI5JGIXywJyMeta } from "/vercel/path0/pages/auth.vue?macro=true";
import { default as indexqadxfF2kySMeta } from "/vercel/path0/pages/flux/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexK4h5nM5YUkMeta } from "/vercel/path0/pages/recraft-v3/index.vue?macro=true";
import { default as assetssTLbIfpEqNMeta } from "/vercel/path0/pages/team/[team_id]/assets.vue?macro=true";
import { default as _91model_id_93EQJD9kUfe0Meta } from "/vercel/path0/pages/team/[team_id]/edit-model/[model_id].vue?macro=true";
import { default as indexLxNmEPqH6nMeta } from "/vercel/path0/pages/team/[team_id]/index.vue?macro=true";
import { default as _91model_id_93qmEyzZWzbuMeta } from "/vercel/path0/pages/team/[team_id]/models/[model_id].vue?macro=true";
import { default as modelsPcak7Gf10mMeta } from "/vercel/path0/pages/team/[team_id]/models.vue?macro=true";
import { default as playgroundH59qFVGBHSMeta } from "/vercel/path0/pages/team/[team_id]/playground.vue?macro=true";
import { default as _91team_id_93QTylUyHmwPMeta } from "/vercel/path0/pages/team/[team_id].vue?macro=true";
import { default as teamThGqkFwfKIMeta } from "/vercel/path0/pages/team.vue?macro=true";
import { default as indexlux5sl9VGoMeta } from "/vercel/path0/pages/text-to-image/index.vue?macro=true";
export default [
  {
    name: "@team_name",
    path: "/@:team_name()",
    component: () => import("/vercel/path0/pages/@[team_name].vue"),
    children: [
  {
    name: "@team_name-model_slug",
    path: ":model_slug()",
    component: () => import("/vercel/path0/pages/@[team_name]/[model_slug].vue")
  },
  {
    name: "@team_name-folder-folder_id",
    path: "folder/:folder_id()",
    component: () => import("/vercel/path0/pages/@[team_name]/folder/[folder_id].vue")
  }
]
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/vercel/path0/pages/auth.vue")
  },
  {
    name: "flux",
    path: "/flux",
    component: () => import("/vercel/path0/pages/flux/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "recraft-v3",
    path: "/recraft-v3",
    component: () => import("/vercel/path0/pages/recraft-v3/index.vue")
  },
  {
    name: "team",
    path: "/team",
    component: () => import("/vercel/path0/pages/team.vue"),
    children: [
  {
    name: _91team_id_93QTylUyHmwPMeta?.name,
    path: ":team_id()",
    component: () => import("/vercel/path0/pages/team/[team_id].vue"),
    children: [
  {
    name: "team-team_id-assets",
    path: "assets",
    component: () => import("/vercel/path0/pages/team/[team_id]/assets.vue")
  },
  {
    name: "team-team_id-edit-model-model_id",
    path: "edit-model/:model_id()",
    component: () => import("/vercel/path0/pages/team/[team_id]/edit-model/[model_id].vue")
  },
  {
    name: "team-team_id",
    path: "",
    component: () => import("/vercel/path0/pages/team/[team_id]/index.vue")
  },
  {
    name: "team-team_id-models",
    path: "models",
    component: () => import("/vercel/path0/pages/team/[team_id]/models.vue"),
    children: [
  {
    name: "team-team_id-models-model_id",
    path: ":model_id()",
    component: () => import("/vercel/path0/pages/team/[team_id]/models/[model_id].vue")
  }
]
  },
  {
    name: "team-team_id-playground",
    path: "playground",
    component: () => import("/vercel/path0/pages/team/[team_id]/playground.vue")
  }
]
  }
]
  },
  {
    name: "text-to-image",
    path: "/text-to-image",
    component: () => import("/vercel/path0/pages/text-to-image/index.vue")
  }
]