import posthog from 'posthog-js'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  const posthogClient = posthog.init(config.public.posthogPublicKey, {
    api_host: config.public.posthogHost,
    person_profiles: 'identified_only',
    enable_recording_console_log: true,
    capture_pageview: false, // we add manual pageview capturing below
    loaded: (posthog) => {
      if (import.meta.env.MODE === 'development') posthog.debug()
    }
  })

  nuxtApp.provide('posthog', posthogClient)

  // Make sure that pageviews are captured with each route change
  const router = useRouter()
  router.afterEach((to) => {
    nextTick(() => {
      posthog.capture('$pageview', {
        current_url: to.fullPath
      })
    })
  })
})
