import { useNuxtApp } from 'nuxt/app'
import { StorageSerializers } from '@vueuse/core'

export const useAppStore = defineStore('appStore', {
  state: () => ({
    // User
    inited: false,
    session: null,
    user: null,

    loading_resources: false,
    grid_cols: useLocalStorage('ms-grid_cols', 6),
    grid_cols_alt: useLocalStorage('ms-grid_cols_alt', 6),

    // Team
    teams: [],
    selected_team_id: useLocalStorage('ms-selected_team_id', null),

    // Folder
    folders: [],
    selected_folder: useLocalStorage('ms-selected_folder', null, {
      serializer: StorageSerializers.object
    }),

    // Files
    files: [],
    selected_files: [],

    // Search
    search_files: [],

    // Model
    models: [],
    selected_model_id: useLocalStorage('ms-selected_model_id', null),
    model_filters: useLocalStorage('ms-model_filters', {
      base: []
    }),

    // Prediction
    predictions: []
  }),
  actions: {
    reset() {
      this.user = null

      this.loading_resources = false
      this.grid_cols = 6
      this.grid_cols_alt = 6

      this.teams = []
      this.selected_team_id = null

      this.folders = []
      this.selected_folder = null

      this.files = []
      this.selected_files = []

      this.search_files = []

      this.models = []
      this.selected_model_id = null
      this.model_filters = {
        base: []
      }

      this.predictions = []
    },

    // Misc.
    setLoadingResources(val) {
      this.loading_resources = val
    },
    setGridCols(val) {
      this.grid_cols = val
    },
    setGridColsAlt(val) {
      this.grid_cols_alt = val
    },
    async getTeamName(name) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/team-name', {
        method: 'GET',
        query: {
          name
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },

    // Onboarding
    async createOnboarding() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/onboarding', {
        method: 'POST'
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalTeam(data)

      return data
    },

    // Stripe
    async getSubscription() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/stripe/subscription', {
        method: 'GET',
        query: {
          team_id: this.selected_team_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async createCheckout({ price }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/stripe/checkout', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          price
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async createCustomerPortal() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/stripe/customer-portal', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },

    // Team
    async createTeam({ name, picture, display_name }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/team', {
        method: 'POST',
        body: {
          name,
          picture,
          display_name
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalTeam(data)

      return data
    },
    async updateTeam({ name, picture, display_name }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/team', {
        method: 'PATCH',
        body: {
          team_id: this.selected_team_id,
          name,
          picture,
          display_name
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalTeam(data)

      return data
    },
    async listTeams() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/team')

      if (error) {
        throw new Error(error)
      }

      this.teams = data
    },
    setSelectedTeamID(id) {
      this.selected_team_id = id
    },
    upsertLocalTeam(team) {
      const index = this.teams.findIndex((i) => i.id === team?.id)
      if (index !== -1) {
        this.teams[index] = team
      } else {
        this.teams.push(team)
      }
    },
    deleteLocalTeam({ id }) {
      const index = this.teams.findIndex((team) => team.id === id)
      if (index !== -1) {
        this.teams.splice(index, 1)
      }
    },

    // Folder
    async createFolder({ inode_id, name }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/folder', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          inode_id,
          name
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalFolder(data)
      this.setSelectedFolder(data)

      return data
    },
    async getFolder({ folder_id }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/folder', {
        method: 'GET',
        query: {
          team_id: this.selected_team_id,
          folder_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalFolder(data[0])
    },
    async updateFolder({ id, inode_id, name, privacy }) {
      // Eager
      this.upsertLocalFolder({ id, inode_id, name, privacy })

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/folder', {
        method: 'PATCH',
        body: {
          team_id: this.selected_team_id,
          folder_id: id,
          inode_id,
          name,
          privacy
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalFolder(data)

      // Edge case where selected folder isn't updated properly
      if (this.selected_folder?.id === data?.id) {
        this.setSelectedFolder(data)
      }

      return data
    },
    async deleteFolder({ id }) {
      this.deleteLocalFolder({ id })

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/folder', {
        method: 'DELETE',
        body: {
          team_id: this.selected_team_id,
          folder_id: id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async listFolders() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/folder', {
        query: {
          team_id: this.selected_team_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.folders = data
    },
    setSelectedFolder(val) {
      this.selected_folder = val
    },
    upsertLocalFolder(folder) {
      const index = this.folders.findIndex((i) => i.id === folder?.id)
      if (index >= 0) {
        // We don't use merge here bcs folders are special
        if (folder?.inode_id || folder?.inode_id === null) {
          this.folders[index].inode_id = folder?.inode_id
        }
        if (folder?.name) {
          this.folders[index].name = folder?.name
        }
        if (folder?.privacy) {
          this.folders[index].privacy = folder?.privacy
        }
        if (folder?.file_count >= 0) {
          this.folders[index].file_count = folder?.file_count
        }
      } else {
        this.folders.push(folder)
      }
    },
    deleteLocalFolder({ id }) {
      const index = this.folders.findIndex((folder) => folder.id === id)
      if (index !== -1) {
        this.folders.splice(index, 1)
      }
    },

    // File
    async createFile({ inode_id, name, type }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/file', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          inode_id,
          name,
          type
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async updateFile({ id, inode_id, name }) {
      // Eager
      // Currently we only allow files in the selected folder to be shown
      if (this.selected_folder?.id === inode_id) {
        this.upsertLocalFile({ id, inode_id, name }, true)
      }

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/file', {
        method: 'PATCH',
        body: {
          team_id: this.selected_team_id,
          file_id: id,
          inode_id,
          name
        }
      })

      if (error) {
        throw new Error(error)
      }

      // Currently we only allow files in the selected folder to be shown
      if (this.selected_folder?.id === inode_id) {
        this.upsertLocalFile(data)
      }

      return data
    },
    async deleteFiles(files) {
      files.forEach((file) => this.deleteLocalFile(file))

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/file', {
        method: 'DELETE',
        body: {
          team_id: this.selected_team_id,
          file_ids: files.map((file) => file?.id)
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async listFiles({ inode_id, model_id }) {
      this.files = []

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/file', {
        query: {
          team_id: this.selected_team_id,
          inode_id,
          model_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.files = data
    },
    setSelectedFiles(val) {
      this.selected_files = val
    },
    upsertLocalFile(file, merge = false) {
      const index = this.files.findIndex((i) => i.id === file?.id)
      if (index !== -1) {
        if (merge) {
          this.files[index] = { ...this.files[index], ...file }
        } else {
          this.files[index] = file
        }
      } else {
        this.files.push(file)
      }
    },
    deleteLocalFile({ id }) {
      const index = this.files.findIndex((file) => file.id === id)
      if (index !== -1) {
        this.files.splice(index, 1)
      }
    },

    // Training file
    async createTrainingFile({ model_id, name, type }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/training-file', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          model_id,
          name,
          type
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async createTrainingFileCopy({ model_id, files }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/training-file-copy', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          model_id,
          files
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async updateTrainingFile({ id, captions }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/training-file', {
        method: 'PATCH',
        body: {
          team_id: this.selected_team_id,
          training_file_id: id,
          captions
        }
      })

      if (error) {
        throw new Error(error)
      }

      // this.upsertLocalTrainingFile(data)

      return data
    },
    async deleteTrainingFiles(training_files) {
      training_files.forEach((training_file) =>
        this.deleteLocalTrainingFile(training_file)
      )

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/training-file', {
        method: 'DELETE',
        body: {
          team_id: this.selected_team_id,
          training_files
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    upsertLocalTrainingFile(training_file) {
      // First, find model
      const modelIndex = this.models.findIndex(
        (i) => i.id === training_file?.model_id
      )
      if (modelIndex !== -1) {
        // Then, find training file
        const trainingFileIndex = this.models[
          modelIndex
        ].training_files.findIndex((i) => i.id === training_file?.id)

        if (trainingFileIndex !== -1) {
          this.models[modelIndex].training_files[trainingFileIndex] =
            training_file
        } else {
          this.models[modelIndex].training_files.push(training_file)
        }
      }
    },
    deleteLocalTrainingFile({ id, model_id }) {
      // First, find model
      const modelIndex = this.models.findIndex((i) => i.id === model_id)
      if (modelIndex !== -1) {
        // Then, find training file
        const trainingFileIndex = this.models[
          modelIndex
        ].training_files.findIndex((i) => i.id === id)

        if (trainingFileIndex !== -1) {
          this.models[modelIndex].training_files.splice(trainingFileIndex, 1)
        }
      }
    },

    // Search
    async search({ inode_id, query }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/search', {
        query: {
          team_id: this.selected_team_id,
          inode_id,
          query
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.search_files = data
    },
    setSearchFiles(val) {
      this.search_files = val
    },
    deleteLocalSearchFile({ id }) {
      const index = this.search_files.findIndex((file) => file.id === id)
      if (index !== -1) {
        this.search_files.splice(index, 1)
      }
    },

    // Model
    async createModel({ type }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/model', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          type
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalModel(data)

      return data
    },
    async updateModel({
      id,
      base,
      name,
      description,
      privacy,
      tags,
      picture,
      trainings,
      metadata
    }) {
      // Eager
      this.upsertLocalModel(
        { id, base, name, description, tags, privacy, metadata },
        true
      )

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/model', {
        method: 'PATCH',
        body: {
          team_id: this.selected_team_id,
          model_id: id,
          base,
          name,
          description,
          privacy,
          tags,
          picture,
          trainings,
          metadata
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalModel(data)

      return data
    },
    async getModel({ model_id }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/model', {
        query: {
          team_id: this.selected_team_id,
          model_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async listModels() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/model', {
        query: {
          team_id: this.selected_team_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.models = data
    },
    async deleteModel(model) {
      // Eager
      this.deleteLocalModel(model)

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/model', {
        method: 'DELETE',
        body: {
          team_id: this.selected_team_id,
          model_id: model?.id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    setSelectedModelID(id) {
      this.selected_model_id = id
    },
    upsertLocalModel(model, merge = false) {
      const index = this.models.findIndex((i) => i.id === model?.id)
      if (index !== -1) {
        if (merge) {
          const filteredModel = Object.fromEntries(
            Object.entries(model).filter(([_, value]) => value !== undefined)
          )
          this.models[index] = { ...this.models[index], ...filteredModel }
        } else {
          this.models[index] = model
        }
      } else {
        this.models.push(model)
      }
    },
    deleteLocalModel({ id }) {
      const index = this.models.findIndex((model) => model.id === id)
      if (index !== -1) {
        this.models.splice(index, 1)
      }
    },

    // Prediction
    async createPrediction({
      inode_id,
      prediction,
      model_id,
      trainings,
      input,
      dry_run = false
    }) {
      if (dry_run === true) {
        const { $fetch } = useNuxtApp()
        const { data, error } = await $fetch('/api/prediction', {
          method: 'POST',
          body: {
            team_id: this.selected_team_id,
            model_id,
            trainings,
            input,
            dry_run
          }
        })

        if (error) {
          throw new Error(error)
        }

        return data
      }

      // Eager
      this.upsertLocalPrediction(prediction)

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/prediction', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          inode_id,
          model_id,
          trainings,
          input
        }
      })

      if (error) {
        this.upsertLocalPrediction({ ...prediction, status: 'failed' })
        throw new Error(error)
      }

      // Swap is used since ID has changed
      this.swapLocalPrediction(prediction, data)
    },
    setPredictions(val) {
      this.predictions = val
    },
    upsertLocalPrediction(prediction) {
      const index = this.predictions.findIndex((i) => i.id === prediction?.id)
      if (index !== -1) {
        this.predictions[index] = prediction
      } else {
        this.predictions.unshift(prediction)
      }
    },
    swapLocalPrediction(old_prediction, new_prediction) {
      const index = this.predictions.findIndex(
        (i) => i.id === old_prediction?.id
      )
      if (index !== -1) {
        this.predictions[index] = new_prediction
      } else {
        this.predictions.push(new_prediction)
      }
    },

    // Training
    async createTraining({ model_id, dry_run }) {
      if (dry_run === true) {
        const { $fetch } = useNuxtApp()
        const { data, error } = await $fetch('/api/training', {
          method: 'POST',
          body: {
            team_id: this.selected_team_id,
            model_id,
            dry_run
          }
        })

        if (error) {
          throw new Error(error)
        }

        return data
      }

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/training', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          model_id
        }
      })

      if (error) {
        this.upsertLocalModel({ model_id, status: 'draft' })
        throw new Error(error)
      }

      return data
    }
  },
  getters: {
    personal_team_id: (state) =>
      state.user?.user_metadata?.personal_team_id || null,
    personal_team: (state) =>
      state.teams.find(
        (team) => team.id === state.user?.user_metadata?.personal_team_id
      ) || null,
    selected_team: (state) =>
      state.teams.find((team) => team.id === state.selected_team_id) || null,
    selected_model: (state) =>
      state.models.find((model) => model.id === state.selected_model_id) ||
      null,
    folder_tree: (state) => {
      // Create a map to store all folders by their id
      const folderMap = new Map(
        state.folders.map((folder) => [folder.id, { ...folder, children: [] }])
      )

      // Array to store root-level folders
      const rootFolders = []

      // Iterate through all folders to build the tree structure
      for (const folder of folderMap.values()) {
        if (folder.inode_id === null) {
          // This is a root folder
          rootFolders.push(folder)
        } else {
          // This folder has a parent
          const parent = folderMap.get(folder.inode_id)
          if (parent) {
            parent.children.push(folder)
          } else {
            console.warn(
              `Parent with id ${folder.inode_id} not found for folder ${folder.id}`
            )
          }
        }
      }

      // Sort children arrays alphabetically by name
      const sortChildren = (folder) => {
        folder.children.sort((a, b) => a.name.localeCompare(b.name))
        folder.children.forEach(sortChildren)
      }

      rootFolders.forEach(sortChildren)

      // Sort root folders alphabetically by name
      rootFolders.sort((a, b) => a.name.localeCompare(b.name))

      return rootFolders
    },
    // Playground files are a mix of predictions and files
    playground_files: (state) => {
      return state.predictions
        .map((prediction) => {
          // Reference file if possible
          const file = state.files.find(
            (file) => file.id === prediction?.file_id
          )
          return file || prediction
        })
        .filter((prediction) => {
          if (prediction?.progress) {
            return prediction.progress < 100
          } else {
            return true
          }
        })
    }
  }
})
