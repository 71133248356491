import { createClient } from '@supabase/supabase-js'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  const supabase = createClient(
    config.public.supabaseUrl,
    config.public.supabaseKey
  )

  nuxtApp.provide('supabase', supabase)

  const appStore = useAppStore()

  // Access PostHog instance
  const posthog = nuxtApp.$posthog

  // Sync user store with auth state
  supabase.auth.onAuthStateChange(async (event, session) => {
    console.log('--- (supabase) debug:', event)

    appStore.session = session
    appStore.user = session?.user

    try {
      if (event === 'INITIAL_SESSION') {
        appStore.inited = true
        await refreshSession()

        // Identify user in PostHog
        if (session?.user) {
          posthog.identify(session.user.id)
        }
      } else if (event === 'SIGNED_IN') {
      } else if (event === 'SIGNED_OUT') {
        appStore.reset()
        posthog.reset()
      } else if (event === 'PASSWORD_RECOVERY') {
      } else if (event === 'TOKEN_REFRESHED') {
      } else if (event === 'USER_UPDATED') {
      }
    } catch (e) {
      console.log('--- (supabase) error:', e.message)
    }
  })

  const refreshSession = async () => {
    const {
      data: { session, user }
    } = await supabase.auth.refreshSession()
    appStore.session = session
    appStore.user = user
  }

  nuxtApp.provide('refreshSession', refreshSession)

  // Create a fetch wrapper with authentication headers set
  const fetch = async (url, options = {}) => {
    if (appStore.session) {
      options.headers = options.headers || {}
      options.headers.Authorization = `Bearer ${appStore.session?.access_token}`
    }

    return $fetch(url, options)
  }

  nuxtApp.provide('fetch', fetch)
})
